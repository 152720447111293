import BlueBg from './containers/BlueBg'
import React from 'react'
import Login from './components/Login'
import ProgressModal from './components/ProgressModal'
import Footer from './components/Footer'


function App(){
  return (
    <React.Fragment>
      <BlueBg>
        <Login></Login> 
      </BlueBg>
      <ProgressModal/>
      <Footer />
    </React.Fragment>
  );
}

export default App;
