import { encryptData,decryptData } from "./securityService"

const base = "/api"

async function post(url,data={},key){
  data.origin = window.location.protocol + '//' + window.location.host + window.location.pathname
  const bodyData = key ? await encryptData(data,key) : {data:{origin:data.origin}}
  const config = {
    method: 'POST',
    body: JSON.stringify(bodyData),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    }
  }
  const res = await fetch(base + url,config)
  const encryptedData = await res.json();
  if(!encryptedData.key){
    return encryptedData
  }
  return decryptData(encryptedData)
}

export default {post}