import useSWR from 'swr'
import fetchDataService from '../services/fetchDataService'
import { messages } from '../config/config'
import { generateFrontKeys } from '../services/securityService'


function useIndex(token){
  const tokenData = token ? {token: token.token} : {}
  const config = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    dedupingInterval: 5000
  }
  const fetcher = () => fetchDataService.post('/security/auth/load-config',tokenData,token.key)
  const {data,error} = useSWR(()=>token ? '/security/auth/load-config':null,fetcher,config)

  return {
    content: data,
    error
  }
}

function useLogin(loginData,updateConfig,key){

  const fetcher = fetchDataService.post('/security/auth/authenticatesession',loginData,key)  
  fetcher.then(res=>{
    //console.log(res)
    if(res.status !== 200){
      updateConfig({
        errorMessage: res.errorMessage,
        showProgress: false
      })
    }else{
      window.location.href = res.redirect
    }
  }).catch(err=>{
    console.log(err)
  })
}

function useForgot(event,updateConfig,key){
  const forgotData = {
    email: event.target[0].value,
  }
  const fetcher = fetchDataService.post('/security/auth/forgot-password',forgotData,key)  
  fetcher.then(res=>{
    //console.log(res)
    event.target[0].value = ''
    updateConfig({
      errorMessage: res.errorMessage || res.message,
      showProgress: false
    })
  }).catch(err=>{
    updateConfig({
      errorMessage: messages.login.default.alert,
      showProgress: false
    })
    console.log(err)
  })
}

function useToken(){
  const serverKeyFetcher = () => fetchDataService.post('/public/key')
  const tokenFetcher = (args) => fetchDataService.post(args[0],{
      typeToken: 'csrfToken',
      timeToken: 5
    },args[1]
  ) 
  const frontKeyFetcher = async () => {
    await generateFrontKeys
    const key = localStorage.getItem('frontKey')
    return {key}
  }

  const {data:frontKey} = useSWR('generatefrontkeys',frontKeyFetcher)
  const {data:keyData} = useSWR(()=> frontKey ? '/public/key':null,serverKeyFetcher)
  const {data:tokenData} = useSWR(()=>keyData ? ['/security/auth/create-session',keyData.data]:null,
    tokenFetcher
  )

  return {
    tokenData,
  }
}

export default {
  useIndex,
  useLogin,
  useForgot,
  useToken
}