import React,{useEffect} from "react";
import {messages} from "../config/config"
import useAuth from "../hooks/useAuth";
import { useConfig } from "../contexts/ConfigContext";

function Login(){

  const {tokenData} = useAuth.useToken()
  const {content,error:eIndex} = useAuth.useIndex(tokenData)
  const {config, updateConfig} = useConfig()

  useEffect(()=>{
    if(tokenData && content) {
      updateConfig(content)
    }
  },[tokenData,content,updateConfig])
  
  if(eIndex) alert('No fue posible conectar con el servidor')
  
  const onLogin = (e) => {
    e.preventDefault()
    updateConfig({showProgress: true})
    const data = {
      email: e.target[0].value,
      password: e.target[1].value
    }
    useAuth.useLogin(data,updateConfig,tokenData.key)
  }

  return (
    <div className="row">
      <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
      <div className="col-lg-6">
        <div className="p-5">
          <div className="text-center">
            <h1 className="h4 text-gray-900 mb-4">
              {config.tittle}
            </h1>
            <p className="mb-4">{config.message}</p>
          </div>
          <form className="user mt-5" onSubmit={onLogin}>
            <div className="form-group">
              <input
                type="email"
                className="form-control form-control-user"
                id="exampleInputEmail"
                aria-describedby="emailHelp"
                placeholder={messages.login.default.form.emailPlaceholder}
                minLength="8"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control form-control-user"
                id="exampleInputPassword"
                placeholder={messages.login.default.form.passwordPlaceholder}
                minLength="4"
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-user btn-block mt-4"
              disabled={!(config.enableLogin)}
            >
              {messages.login.default.form.buttonText}
            </button>
          </form>
          <hr />
          <div className="text-center text-danger">
            <h4>{(config.errorMessage) || ''}</h4>
          </div>
          <hr />
          <div className="text-center">
            <a className="small" href="forgot-password">
            {messages.login.default.forgotText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Login;
