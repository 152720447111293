import React, { createContext, useContext, useState, useCallback } from 'react';
import { messages } from '../config/config';
const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const page = window.location.pathname.includes('login') ? 'login':'forgotPassword'
  const [config, setConfig] = useState({
    showProgress: true,
    errorMessage: "",
    tittle: messages[page].default.title,
    message: messages[page].default.message,
    enableLogin: false,
    enableForgot: false
  });
  const updateConfig = useCallback((newConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);

  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};