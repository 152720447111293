import React from 'react'
import BlueBg from '../../containers/BlueBg'
import ForgotPassword from './ForgotPassword'
import ProgressModal from '../ProgressModal'
import Footer from '../Footer'


function Index(){
  return(
    <React.Fragment>
      <BlueBg>
        <ForgotPassword/> 
      </BlueBg>
      <ProgressModal/>
      <Footer />
    </React.Fragment>
  )
}

export default Index