import ProgressModal from "../components/ProgressModal";

function BlueBg(props) {
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
      <ProgressModal></ProgressModal>
    </div>
  );
}

export default BlueBg;
