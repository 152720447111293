import {messages} from '../../config/config.js'
import useAuth from '../../hooks/useAuth.js'
import { useConfig } from '../../contexts/ConfigContext.js'
import { useEffect } from 'react'

function ForgotPassword(){
  const {tokenData} = useAuth.useToken()
  const {config,updateConfig} = useConfig()
  
  const {
    content,
    error
  } = useAuth.useIndex(tokenData)

  useEffect(()=>{
    if(tokenData && content) {
      updateConfig(content)
    }
  },[tokenData,content,updateConfig])

  if(error){
    alert('No fue posible conectar con el servidor')
  } 
  const handleForgot = (e) => {
    e.preventDefault()
    updateConfig({showProgress: true})
    useAuth.useForgot(e,updateConfig,tokenData.key)
  }
  return (
    <div className="row">
      <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
      <div className="col-lg-6">
        <div className="p-5">
          <div className="text-center">
            <h1 className="h4 text-gray-900 mb-4">
              {config.tittle}
            </h1>
            <p className="mb-4">{config.message}</p>
          </div>
          <form className="user mt-5" onSubmit={handleForgot}>
            <div className="form-group">
              <input
                type="email"
                className="form-control form-control-user"
                id="exampleInputEmail"
                aria-describedby="emailHelp"
                placeholder={messages.forgotPassword.default.form.emailPlaceholder}
                minLength="8"
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-user btn-block mt-4"
              disabled={!(config.enableForgot)}
            >
              {messages.forgotPassword.default.form.buttonText}
            </button>
          </form>
          <hr />
          <div className="text-center text-danger">
            <h4>{config.errorMessage}</h4>
          </div>
          <hr />
          <div className="text-center">
            <a className="small" href="/login">
            {messages.forgotPassword.default.accountText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword