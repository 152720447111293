import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'
import App from './App'
import Error from './404'
import ForgotPassword from './components/fortgotPassword/Index'

function Routes(){
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={App}/>
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route component={Error} />
      </Switch>
    </Router>
  )
}

export default Routes